/**************************************************************

 Sidley Blue-Sky master stylesheet
 Written by Ka Wai Cheung
 Hubbard One 2004

***************************************************************/
.hidden {
    display: none;
    visibility: hidden;
}

.visible {
    display: block;
    visibility: visible;
}

body {
    background-color: #63859F;
    margin: 0;
    padding: 0;
}

p {
    font-family: Verdana, Arial, Times New Roman;
    font-size: x-small;
    margin: 0 0 10px;
    padding: 0;
}

hr {
    border: dashed 1px #999;
    padding: 0;
    margin: 0;
}

div#headerInfoBlock {
    font-family: Arial, Times New Roman;
    font-size: small;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    padding: 10px;
    border: solid 1px #efefef;
    border-top: none;
    border-bottom: solid 1px #ccc;
    background-color: #E7F2FF;
    width: auto;
}

h1 {
    font-family: Arial, Times New Roman;
    font-size: small;
    padding: 5px 0 5px 10px;
    margin: 0;
    width: auto;
    background-color: #E7F2FF;
    border-bottom: solid 1px #ccc;
    color: #000;
}

div#section {
    font-family: Verdana, Arial, Times New Roman;
    margin: 0;
    padding: 10px;
    border-left: solid 1px #efefef;
    // width: 97.9%;
    background-color: #fff;
}

a {
    font-family: Verdana, Arial, Times New Roman;
    font-size: 100%;
    color: red;
}
/* Error div */
div.error {
    border-left: solid 5px #cc3333;
    color: #cc3333;
    padding: 0 0 0 5px;
    margin: 0;
}

div#error {
    font-family: Verdana, Arial, Times New Roman;
    font-size: small;
    font-weight: normal;
    padding: 10px;
    margin: 0 0 10px;
    width: auto;
    border: dashed 1px #999;
    border-right: none;
    border-left: solid 1px #999;
    background-color: yellow;
    width: auto;
    color: #cc3333;
}

div#error ol {
    margin: 10px 0 0;
    padding: 0 0 0 30px;
}

div#error li {
    font-size: small;
    margin: 0;
    padding: 0 0 5px;
}

div#header {
    font-family: Arial, Times New Roman;
    font-size: small;
    font-weight: bold;
    padding: 10px;
    width: auto;
    // border: solid 1px #efefef;
    background-color: #78B4FD;
    color: #fff;
}

div#headerMenu {
    font-family: Verdana,Arial, Times New Roman;
    font-size: xx-small;
    padding: 8px;
    width: auto;
    text-align: right;
    // border: solid 1px #efefef;
    // border-bottom: none;
    background-color: #63859F;
    color: #fff;
}

div#headerMenu a {
    color: #fff;
    // font-weight: bold;
}

div#headerMenu a:hover {
    // color: yellow;
    // font-weight: bold;
}

div.smallText {
    font-family: Verdana, Arial, Times New Roman;
    font-size: xx-small;
    font-weight: normal;
}

div#footer {
    font-family: Arial, Times New Roman;
    font-size: xx-small;
    font-weight: bold;
    padding: 10px 10px 50px;
    color: #fff;
    margin: 0 0 10px;
    width: auto;
    background-color: #63859F;
}

div#footer a {
    color: #fff;
    font-weight: bold;
}

div#footer a:hover {
    color: yellow;
    font-weight: bold;
}
/* Module styles */
div.module {
    font-family: Verdana, Arial, Times New Roman;
    margin: 0;
    // border-top: dashed 1px #999;
    // border-left: solid 1px #999;
    padding: 10px;
    // background-color: #efefef;
    color: #000;
    width: auto;
}

div.moduleClientManagement {
    font-family: Verdana, Arial, Times New Roman;
    font-size: 70%;
    margin: 0;
    border-top: dashed 1px #999;
    border-left: solid 10px;
    padding: 10px;
    background-color: #F8F8F8;
    color: #000;
    width: auto;
}

div.moduleBackToTop {
    font-family: Arial, Times New Roman;
    font-size: xx-small;
    margin: 0;
    padding: 5px 10px;
}

div.moduleClientManagement h1 {
    font-size: small;
    margin: 0;
    padding: 0 0 10px;
    background: none;
    border: none;
    color: #000;
}

div.moduleHeader,
div.moduleheader {
    // font-family:  arial;
    // font-size: medium;
    // font-weight: bold;
    // margin: 0 0 0 0;
    // border-left: solid 1px #999;
    padding: 5px 10px;
    // background-color: #fff;
    // color: #000;
    width: auto;
}

div.moduleHeader p,
div.moduleheader p {
    font-family: Verdana, Arial, Times New Roman;
    font-size: x-small;
    font-weight: normal;
    margin: 0;
    padding-top: 5px;
    color: #000;
    width: auto;
}

div.moduleHeader span.name,
div.moduleheader span.name {
    font-family: arial;
    font-size: x-small;
    font-weight: normal;
}

div.module p {
    font-family: Verdana, Arial, Times New Roman;
    font-size: x-small;
}

div.moduleFooter,
div.modulefooter {
    font-family: Verdana, Arial, Times New Roman;
    font-size: x-small;
    // font-weight: bold;
    margin: 0 0 10px;
    // border-left: solid 1px #999;
    // padding: 5px 10px 5px 10px;
    // background-color: #fff;
    // color: #000;
    width: auto;
}
/* Module disabled styles */
div.moduleDisabled,
div.moduledisabled {
    font-family: Verdana, Arial, Times New Roman;
    //margin: 0;
    //border-top: dashed 1px #ccc;
    //border-left: solid 1px #ccc;
    //padding: 10px;
    //background-color: #efefef;
    //color: #ccc;
    //color: #000;
    width: auto;
}

div.moduleDisabled p,
div.moduledisabled p {
    font-family: Verdana, Arial, Times New Roman;
    //font-size: x-small;
    color: #ccc;
}

div.moduleDisabled input,
div.moduleDisabled select,
div.moduleDisabled textarea,
div.moduledisabled input,
div.moduledisabled select,
div.moduledisabled textarea {
    background-color: #efefef;
    color: #ccc;
    border: #ccc;
}

div.moduleFooterDisabled input.button,
div.moduleFooterdisabled input.button,
div.modulefooterDisabled input.button,
div.modulefooterdisabled input.button {
    background-color: #efefef;
    color: #ccc;
    border: #ccc;
}

div.moduleHeaderDisabled,
div.moduleHeaderdisabled,
div.moduleheaderDisabled,
div.moduleheaderdisabled {
    font-family: arial;
    font-size: medium;
    //font-weight: bold;
    margin: 0;
    //border-left: solid 1px #ccc;
    //padding: 5px 10px;
    //background-color: #fff;
    //color: #ccc;
    width: auto;
}

div.moduleHeaderDisabled span.name,
div.moduleHeaderdisabled span.name,
div.moduleheaderDisabled span.name,
div.moduleheaderdisabled span.name {
    font-family: arial;
    font-size: x-small;
    font-weight: normal;
}

div.moduleFooterDisabled,
div.moduleFooterdisabled,
div.modulefooterDisabled,
div.modulefooterdisabled {
    font-family: Verdana, Arial, Times New Roman;
    //font-size: x-small;
    //font-weight: bold;
    margin: 0 0 10px;
    //border-left: solid 1px #ccc;
    //padding: 5px 10px;
    //background-color: #fff;
    //color: #efefef;
    width: auto;
}

table#steps {
    font-family: Verdana, Arial, Times New Roman;
    padding: 0 0 0 px 0;
    margin: 0;
    width: 100%;
    border-bottom: dashed 1px #ccc;
    background-color: yellow;
}

table#steps td {
    color: #999;
    font-size: x-small;
}

table#steps td.navSelected {
    color: #000;
    font-weight: bold;
    font-size: x-small;
}

table#steps a {
    color: #000;
}

table#steps a:hover {
    color: #999;
}

table.listing {
    width: 100%;
    padding: 0;
    margin: 0;
}

table.listing a {
    color: red;
}

table.listing a:hover {
    color: Navy;
}
/* Layout is the default table with no associative styles for layout purposes */
table.layout {
    width: 97%;
}

td {
    font-family: Verdana, Arial, Times New Roman;
    vertical-align: top;
    padding: 5px;
    margin: 0;
}

td.label {
    font-weight: bold;
}

div.clientListingLinks {
    margin-top: 8px;
    background-color: #fff;
    margin-bottom: 5px;
    border: solid 1px #ccc;
    padding: 5px;
    line-height: 15px;
    text-align: left;
}

td.edit {
    background-color: #fff;
    border: solid 1px #ccc;
}

tr {
    padding: 0;
    margin: 0;
}

.altRow {
    background-color: #fff;
}

.altRowSelected,
.altRowselected {
    background-color: #FFCC00;
}

.Selected,
.selected {
    background-color: #FFCC00;
}

tr.header {
    font-weight: bold;
    background-color: #efefef;
}

tr.header td.smalltext {
    font-weight: bold;
    font-size: xx-small;
}

tr.footer {
    font-weight: bold;
    background-color: #efefef;
}

tr.note {
    border: solid 10px #000;
}

input {
    font-family: Verdana, Arial, Times New Roman;
    font-size: x-small;
    margin: 0;
    padding: 1px;
    border: solid 1px #ccc;
    font-family: Verdana, Arial;
    color: #666;
}

textarea {
    font-family: Verdana, Arial, Times New Roman;
    font-size: small;
    margin: 0;
    padding: 1px;
    border: solid 1px #ccc;
    font-family: Verdana, Arial;
    color: #666;
}

h2 {
    font-family: Arial, Times New Roman;
    font-size: 85%;
    padding: 0 0 10px;
    width: auto;
}

input.address {
    width: 300px;
}

input.city {
    width: 200px;
}

input.zip {
    width: 50px;
}

input.zipPlus4 {
    width: 40px;
}

input.phone {
    width: 200px;
}

input.fax {
    width: 200px;
}

input.name {
    width: 300px;
}

input.clientNumber,
input.clientnumber {
    width: 50px;
}

input.currencyConverter {
    width: 60px;
}

input.checkBox {
    border: none;
    width: 15px;
}

input.matterNumber {
    width: 50px;
}

input.file {
    width: 300px;
}

.money {
    color: Green;
    text-align: right;
}

input.money {
    padding: 1px;
}

select {
    border: solid 1px #ccc;
    font-family: Verdana, Arial;
    color: #666;
    font-size: xx-small;
}

select.addNew {
    border: solid 1px #ccc;
    font-family: Verdana, Arial;
    font-size: xx-small;
    width: 280px;
}

select.funds {
    width: 300px;
}

select.clients {
    width: 300px;
}

input.button {
    font-size: x-small;
    color: #fff;
    // border-style: solid;
    // border-width: 1px;
    // border-color: #000;
    // font-weight: normal;
    padding: 0;
    background-color: #63859F;
    width: 150px;
}

input.buttonDisabled {
    font-size: xx-small;
    color: #fff;
    border-style: solid;
    border-width: 1px;
    border-color: #000;
    font-weight: normal;
    background-color: #63859F;
    width: 150px;
}

input.radio {
    border: none;
    width: 20px;
}
/* tableruler test */
tr.hover {
    background-color: yellow;
}

table#id {}

div#reportSteps {
    position: relative;
    left: 150px;
    top: 0;
}

span.hidLink {
    color: #cccccc;
    text-decoration: underline;
}

span.hidText {
    color: #999999;
}

table#aggregateSalesListing {
    background-color: Yellow;
    padding: 0;
    margin: 0;
    border-top: dashed 1px #999;
    border-bottom: dashed 1px #999;
    font-size: medium;
}

table#aggregateSalesListing tr.header {
    font-weight: bold;
    background-color: Yellow;
}

table#aggregateSalesListing tr.nonUSD {
    color: #ccc;
    background-color: Yellow;
}

tr.highlight {
    background-color: Yellow;
}

td.aggregateSalesFundHeader {
    border-bottom: solid 1px #999;
    border-top: solid 1px #999;
    font-weight: bold;
    background-color: #63859F;
    color: #fff;
}

td.aggregateSalesFundHeader a {
    font-size: xx-small;
    color: #fff;
}

td.aggregateAdditionalNotesBegin {}

td.aggregateAdditionalNotesMid {
    border-bottom: solid 1px #999;
    border-left: solid 1px #999;
}

td.aggregateAdditionalNotesEnd {
    border-bottom: solid 1px #999;
}

tr.highlight td.aggregateAdditionalNotesBegin {}

tr.highlight td.aggregateAdditionalNotesMid {}

tr.highlight td.aggregateAdditionalNotesEnd {}

td.aggregateSalesExpandCell {
    background-color: #efefef;
}

td.aggregateSalesExpandCellAltRow {
    background-color: #fff;
}

td.topLevelHeader {
    border-bottom: dashed 1px #999;
}

table.listing td {
    font-family: Verdana, Arial, Times New Roman;
    font-size: 70%;
    vertical-align: top;
    padding: 5px;
    margin: 0;
    border-left: solid 1px #ccc;
}

td.a506DListingSubtotals {
    border-top: solid 1px #999;
}

td.a506DListingTotals {
    border-top: dashed 1px #999;
    border-left: none;
}

td.ExcelErrorCell {
    background-color: #cc3333;
    border-bottom: solid 1px #efefef;
    color: #fff;
}

table.gridLayout {
    width: auto;
    border-left: solid 1px #999;
    border-bottom: solid 1px #999;
}

table.gridLayout tr {
    padding: 0;
}

table.gridLayout td {
    border-left: none;
    width: 10px;
    border-bottom: none;
    border-top: solid 1px #999;
    border-right: solid 1px #999;
}

table.gridLayout td.footerGrid {
    border-left: none;
    border-bottom: none;
    border-top: solid 1px #999;
    border-right: none;
}

td.fundSalesListingTotals {
    border-top: dashed 1px #999;
    border-bottom: dashed 1px #999;
    border-left: none;
    vertical-align: top;
}

td.fundSalesListingTotalsMoney {
    border-top: dashed 1px #999;
    border-bottom: dashed 1px #999;
    vertical-align: top;
    border-left: none;
    color: Green;
    text-align: right;
}

div#section p {
    font-size: small;
}

div.module td {
    font-size: 70%;
}

div.moduleDisabled td,
div.moduledisabled td {
    font-size: 70%;
    color: #ccc;
    // filter:alpha(opacity=90, finishopacity=90);
    filter:alpha(opacity=90);
}

table.layout td.jumpLinks {
    font-size: 70%;
}

div.module table.inset {
    background-color: #fff;
    border: solid 1px #ccc;
}

.g-recaptcha {
    display: inline-block;
    padding-top: 30px;
}
