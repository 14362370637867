//Entry point for reskin styles
@import 'vars';
@import 'original';
@import 'responsive';
@import 'header';
@import 'components';
@import 'tables';
@import 'footer';
@import 'pages';
@import 'interior-page';
@import 'summary-page';
@import 'jumplinks';
@import 'sales-page';
@import 'overlay';

body {
    background-color: $site-background;
}
