$bp-desktop: 1200px;
$bp-tablet: 768px;
@mixin respond-tablet() {
     @media(max-width: $bp-tablet){
        @content;
    }
}
@mixin respond-desktop() {
     @media(max-width: $bp-desktop){
        @content;
    }
}
//respond-mobile
//desktop-only
//mobile-only
//etc
