.sales {
    .container {
        width: 1371px;
        overflow: scroll;
        margin-left: 100px;
        height: 480px;
    }

    .frozen-header {
        margin-left: 100px;

        table {
            border-collapse: collapse;

            td {
                padding: 10px 0 !important;
            }

            div {
                padding-left: 25px;
            }
        }
    }
}
