$module-inner-side-padding: 24px;
$module-inner-vertical-padding: 10px;

body.interior-page {
    &.popup-page {
        div#section {
            padding-left: 0;
            padding-right: 0;
        }
        tr:not(.header){
            td{
                font-size: 15px; 
            }
        }
    }

    a {
        @include link-styling();
    }

    div#section {
        @include full-width-wrapper();
        // min-height: 400px;
    }

    #header + h1,
    #headerInfoBlock + h1 {
        background-color: $site-background;
        border: 0;
        color: $text-grey;
        font-size: 16px;
        padding: $block-vertical-gap-small $site-side-margin;
    }

    input,
    select {
        box-sizing: border-box;
        font-size: $input-font-size;
        height: $input-height-small;
        margin-left: $input-side-margin;
        margin-right: $input-side-margin;
        padding: 0 $input-side-padding;

        &:not(.clientnumber):not(.funds):not(.long-dropdown) {
            width: auto;
            max-width: 100%;

            &.go-button {
                width: 5em;
            }
        }
    }

    select {
        padding-right: 0;
    }

    .clientnumber {
        width: 6em !important;
    }

    .funds,
    .long-dropdown {
        width: 30%;
    }
    .small-font-dropdown {
        
        font-size:11px;
        width:auto;
        max-width:100%;
    }
    //left margin only on elements not on a new line
    br + input,
    br + select,
    input:first-child,
    p + input,
    p + select,
    select:first-child {
        margin-left: 0;
    }

    input.button {
        @include button-styling();
    }

    .file {
        padding-left: 0;
        border: 0;
    }

    .moduleHeader,
    .moduleheader {
        font-size: 24px;
        margin-bottom: 5px;
        padding: 0 0 5px;
        //TODO: specify font

        span.name {
          font-size: small;
        }
    }

    div.moduleHeaderDisabled,
    div.moduleHeaderdisabled,
    div.moduleheaderDisabled,
    div.moduleheaderdisabled {
      font-size: 24px;
      margin-bottom: 5px;
      padding: 0 0 5px;
      color: #ccc;
      //TODO: specify font

      span.name {
        font-size: small;
      }

      a {
        color: #ccc !important;
      }
    }

    .moduleFooter,
    .modulefooter {
        font-size: $base-font-size;
    }

    div.moduleFooterDisabled,
    div.moduleFooterdisabled,
    div.modulefooterDisabled,
    div.modulefooterdisabled {
      font-size: $base-font-size;
      color: #efefef;

      a {
        color: #ccc !important;
      }
    }

    .module,
    .moduleClientManagement {
        @include tile-grey();
        margin-bottom: $block-vertical-gap-small;
        padding: $module-inner-vertical-padding $module-inner-side-padding;
    }

    div.moduleDisabled,
    div.moduledisabled {
      @include tile-grey();
      margin-bottom: $block-vertical-gap-small;
      padding: $module-inner-vertical-padding $module-inner-side-padding;
      color: #ccc;

      a {
        color: #ccc !important;
      }
    }

    .moduleClientManagement {
        border: 1px solid $subtle-grey !important;
        margin-bottom: $block-vertical-gap-small;
    }

    div#error {
        @include tile-grey();
        color: $error-red;
    }

    table.aggregateSalesListing tr.header,
    table.aggregateSalesListing tr.nonUSD,
    tr.highlight {
        background-color: $highlight-color;
    }

    a.anchorJumpLink{
        display:block;
    }
}
