$footer-height: 90px;

div.moduleFooter.single-column,
div.modulefooter.single-column {
    @include page-column-width();
    text-align: center;
}

div#footer {
    background-color: $dark-grey;
    color: $subtle-grey;
    font-size: 14px;
    height: $footer-height;
    line-height: $footer-height;
    margin: 0;
    padding: 0 $site-side-margin;
}
