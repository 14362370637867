$navbar-height: 40px;
$logo-header-height: 100px;
$bigheader-height: 154px;
//topmost nav bar
div#headerMenu {
    $navbar-vertical-padding: 8px;
    box-sizing: border-box;
    height: $navbar-height;
    background-color: $dark-grey;
    //padding: $navbar-vertical-padding $site-side-margin;
    color: $subtle-grey;
    white-space: nowrap;

    a {
        &:hover {
            color: $matte-light-blue;
        }
        text-decoration: none;
        margin: 0 5px;
        font-size: 12px;
        line-height: $navbar-height - ($navbar-vertical-padding * 2);
        text-transform: uppercase;
        font-weight: normal;
    }
}

div#header {
    box-sizing: border-box;
    background-color: $matte-light-blue;
    padding: 0 $site-side-margin;
    height: $logo-header-height;
}

.header-logo-img {
    height: 30px;
    margin-top: calc(($logo-header-height - 30px) / 2);
    width: auto;
}
//bring you back up on top of the bar above it
div#headerInfoBlock {
    background-color: transparent;
    height: $logo-header-height;
    float: right;
    //position: absolute;
    //top: $navbar-height;
    //right: 0;
    line-height: $logo-header-height;
    font-size: 16px;
    padding: 0 $site-side-margin;
    padding-right:0;
    padding-left:0;
    border: none;
    color: $matte-blue;
    white-space: nowrap;
}
//"Welcome to the sidley blue sky extranet"
div.moduleHeader.big-header,
div.moduleheader.big-header {
    margin: $block-vertical-gap $site-side-margin;
    //TODO: change to image
    background-color: $matte-blue;
    color: $text-white;
    height: $bigheader-height;
    line-height: 154px;
    font-size: 40px;
    text-align: center;
    background-image: url('/img/complex-vector.png');
    background-size: 60% auto;
    background-repeat: no-repeat;
    background-position: center 20%;
    @include respond-desktop {
        font-size: 3vw;
    }
}
