$updated-font: 'Arial';

// **********COLORS****************
$dark-grey: #424242;
$matte-light-blue: #8BB4C4;
$matte-blue: #005E8A;
$text-white: #FFF;
$text-grey: #656365;
$error-red: rgba(#c51f4b, 100);
$highlight-color: rgb(245, 219, 108);
$site-background: #fff;
$subtle-grey: #E2E2E2;
$tile-grey: #f5f5f5;
$table-border-color: rgba(#C7C9C8, 0.5);
$link-color: $matte-blue; // $button-disabled-color: rgba($text-grey, .5);
$button-disabled-color: $matte-light-blue;

// *********SIZES **************
$block-vertical-gap: 30px;
$block-vertical-gap-small: 20px;
$site-side-margin: 100px;
$sidebar-side-margin: $site-side-margin; //account for padding
$sidebar-width: 200px + 50px;
$module-side-margin: 20%;
$module-min-width: 700px;
$max-page-width: 1200px;
$input-height: 40px;
$input-height-small: 33px;
$input-font-size: 15px;
$input-side-padding: 15px;
$input-side-margin: 10px;
$base-font-size: 16px;
