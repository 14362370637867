@mixin page-column-width() {
    // min-width: $module-min-width;
    margin-left: $module-side-margin;
    margin-right: $module-side-margin;
}
@mixin full-width-wrapper() {
    padding: 10px $site-side-margin;
}

.full-width-wrapper {
    @include full-width-wrapper();
}
@mixin button-styling($modifier: null) {
    color: $text-white $modifier;
    background-color: $matte-blue $modifier;
    text-transform: uppercase $modifier;
    border: 0 $modifier;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    &:hover {
        background-color: $matte-light-blue $modifier;
    }

    &:disabled {
        @include button-disabled-styling()
    }
}
@mixin button-disabled-styling($modifier: null) {
    background-color: $button-disabled-color $modifier;
    color: $text-white $modifier;
    cursor: not-allowed;
}
@mixin link-styling () {
    color: $matte-blue;
    text-decoration: none;

    &:hover {
        color: $matte-light-blue;
    }
}
@mixin jumplink-styling() {
     @include link-styling();
    text-transform: uppercase;
    font-size: 12px;
}
@mixin border-grey() {
    border: 1px solid $subtle-grey;
}

.border-grey {
    @include border-grey();
}
@mixin tile-grey() {
    background-color: $tile-grey;
    @include border-grey()
}

.tile-grey {
    @include tile-grey();
}
@mixin no-background() {
    background-color: $site-background;
    border: 0;
}

div#section {
    padding-bottom: $block-vertical-gap;
    // max-width: $max-page-width;
    // margin-left: auto;
    // margin-right: auto;
}

div.module.single-column {
    margin-top: $block-vertical-gap;
    margin-bottom: $block-vertical-gap;
    @include page-column-width();
}

.unstyled-module .module {
    border: 0 !important;
    padding: 0 !important;
    background-color: $site-background !important;

    &.lone-button {
        .button:only-of-type {
            margin-top: 1em;
        }
    }
}

input.button.big-button {
    @include button-styling();
    font-size: $input-font-size;
    height: $input-height;
}

input.name {
    font-size: $input-font-size;
    height: $input-height;
    padding-left: $input-side-padding;
    padding-right: $input-side-padding;
}

.input-row {
    margin-top: 1em;

    input:first-child,
    select:first-child {
        margin-left: 0;
    }
}

.narrow-column {
    // margin-bottom: $block-vertical-gap;
    text-align: center;
}

.page-chunk:not(:last-of-type) {
    border-bottom: 2px solid $subtle-grey;
    margin-bottom: $block-vertical-gap;
    padding-bottom: $block-vertical-gap;
}

.full-width {
    box-sizing: border-box; 
    width: 100% !important;
}
@mixin sidebar-offset($attribute: margin-left) {
    #{$attribute}: $sidebar-width + $sidebar-side-margin; // + 25px;
}

.sidebar-offset {
    @include sidebar-offset();
}

.formatted-text {
    white-space: pre-wrap;
}

.module-flex-columns {
    columns: 4;

    @media(max-width: 1024px){
        columns: 3;
    }
    @media(max-width: 850px){
        columns: 2;
    }
    @media(max-width: 650px){
        columns: 1;
    }

    .module-flex-column-child{
        margin-bottom: 20px;
        break-inside:avoid; 
        overflow:hidden
    }
}
