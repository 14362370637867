/**************************************************************

 Sidley Blue-Sky master stylesheet
 Written by Ka Wai Cheung
 Hubbard One 2004

***************************************************************/
.hidden {
  display: none;
  visibility: hidden;
}

.visible {
  display: block;
  visibility: visible;
}

body {
  background-color: #63859F;
  margin: 0;
  padding: 0;
}

p {
  font-family: Verdana, Arial, Times New Roman;
  font-size: x-small;
  margin: 0 0 10px;
  padding: 0;
}

hr {
  border: dashed 1px #999;
  padding: 0;
  margin: 0;
}

div#headerInfoBlock {
  font-family: Arial, Times New Roman;
  font-size: small;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding: 10px;
  border: solid 1px #efefef;
  border-top: none;
  border-bottom: solid 1px #ccc;
  background-color: #E7F2FF;
  width: auto;
}

h1 {
  font-family: Arial, Times New Roman;
  font-size: small;
  padding: 5px 0 5px 10px;
  margin: 0;
  width: auto;
  background-color: #E7F2FF;
  border-bottom: solid 1px #ccc;
  color: #000;
}

div#section {
  font-family: Verdana, Arial, Times New Roman;
  margin: 0;
  padding: 10px;
  border-left: solid 1px #efefef;
  background-color: #fff;
}

a {
  font-family: Verdana, Arial, Times New Roman;
  font-size: 100%;
  color: red;
}

/* Error div */
div.error {
  border-left: solid 5px #cc3333;
  color: #cc3333;
  padding: 0 0 0 5px;
  margin: 0;
}

div#error {
  font-family: Verdana, Arial, Times New Roman;
  font-size: small;
  font-weight: normal;
  padding: 10px;
  margin: 0 0 10px;
  width: auto;
  border: dashed 1px #999;
  border-right: none;
  border-left: solid 1px #999;
  background-color: yellow;
  width: auto;
  color: #cc3333;
}

div#error ol {
  margin: 10px 0 0;
  padding: 0 0 0 30px;
}

div#error li {
  font-size: small;
  margin: 0;
  padding: 0 0 5px;
}

div#header {
  font-family: Arial, Times New Roman;
  font-size: small;
  font-weight: bold;
  padding: 10px;
  width: auto;
  background-color: #78B4FD;
  color: #fff;
}

div#headerMenu {
  font-family: Verdana, Arial, Times New Roman;
  font-size: xx-small;
  padding: 8px;
  width: auto;
  text-align: right;
  background-color: #63859F;
  color: #fff;
}

div#headerMenu a {
  color: #fff;
}

div.smallText {
  font-family: Verdana, Arial, Times New Roman;
  font-size: xx-small;
  font-weight: normal;
}

div#footer {
  font-family: Arial, Times New Roman;
  font-size: xx-small;
  font-weight: bold;
  padding: 10px 10px 50px;
  color: #fff;
  margin: 0 0 10px;
  width: auto;
  background-color: #63859F;
}

div#footer a {
  color: #fff;
  font-weight: bold;
}

div#footer a:hover {
  color: yellow;
  font-weight: bold;
}

/* Module styles */
div.module {
  font-family: Verdana, Arial, Times New Roman;
  margin: 0;
  padding: 10px;
  color: #000;
  width: auto;
}

div.moduleClientManagement {
  font-family: Verdana, Arial, Times New Roman;
  font-size: 70%;
  margin: 0;
  border-top: dashed 1px #999;
  border-left: solid 10px;
  padding: 10px;
  background-color: #F8F8F8;
  color: #000;
  width: auto;
}

div.moduleBackToTop {
  font-family: Arial, Times New Roman;
  font-size: xx-small;
  margin: 0;
  padding: 5px 10px;
}

div.moduleClientManagement h1 {
  font-size: small;
  margin: 0;
  padding: 0 0 10px;
  background: none;
  border: none;
  color: #000;
}

div.moduleHeader,
div.moduleheader {
  padding: 5px 10px;
  width: auto;
}

div.moduleHeader p,
div.moduleheader p {
  font-family: Verdana, Arial, Times New Roman;
  font-size: x-small;
  font-weight: normal;
  margin: 0;
  padding-top: 5px;
  color: #000;
  width: auto;
}

div.moduleHeader span.name,
div.moduleheader span.name {
  font-family: arial;
  font-size: x-small;
  font-weight: normal;
}

div.module p {
  font-family: Verdana, Arial, Times New Roman;
  font-size: x-small;
}

div.moduleFooter,
div.modulefooter {
  font-family: Verdana, Arial, Times New Roman;
  font-size: x-small;
  margin: 0 0 10px;
  width: auto;
}

/* Module disabled styles */
div.moduleDisabled,
div.moduledisabled {
  font-family: Verdana, Arial, Times New Roman;
  width: auto;
}

div.moduleDisabled p,
div.moduledisabled p {
  font-family: Verdana, Arial, Times New Roman;
  color: #ccc;
}

div.moduleDisabled input,
div.moduleDisabled select,
div.moduleDisabled textarea,
div.moduledisabled input,
div.moduledisabled select,
div.moduledisabled textarea {
  background-color: #efefef;
  color: #ccc;
  border: #ccc;
}

div.moduleFooterDisabled input.button,
div.moduleFooterdisabled input.button,
div.modulefooterDisabled input.button,
div.modulefooterdisabled input.button {
  background-color: #efefef;
  color: #ccc;
  border: #ccc;
}

div.moduleHeaderDisabled,
div.moduleHeaderdisabled,
div.moduleheaderDisabled,
div.moduleheaderdisabled {
  font-family: arial;
  font-size: medium;
  margin: 0;
  width: auto;
}

div.moduleHeaderDisabled span.name,
div.moduleHeaderdisabled span.name,
div.moduleheaderDisabled span.name,
div.moduleheaderdisabled span.name {
  font-family: arial;
  font-size: x-small;
  font-weight: normal;
}

div.moduleFooterDisabled,
div.moduleFooterdisabled,
div.modulefooterDisabled,
div.modulefooterdisabled {
  font-family: Verdana, Arial, Times New Roman;
  margin: 0 0 10px;
  width: auto;
}

table#steps {
  font-family: Verdana, Arial, Times New Roman;
  padding: 0 0 0 px 0;
  margin: 0;
  width: 100%;
  border-bottom: dashed 1px #ccc;
  background-color: yellow;
}

table#steps td {
  color: #999;
  font-size: x-small;
}

table#steps td.navSelected {
  color: #000;
  font-weight: bold;
  font-size: x-small;
}

table#steps a {
  color: #000;
}

table#steps a:hover {
  color: #999;
}

table.listing {
  width: 100%;
  padding: 0;
  margin: 0;
}

table.listing a {
  color: red;
}

table.listing a:hover {
  color: Navy;
}

/* Layout is the default table with no associative styles for layout purposes */
table.layout {
  width: 97%;
}

td {
  font-family: Verdana, Arial, Times New Roman;
  vertical-align: top;
  padding: 5px;
  margin: 0;
}

td.label {
  font-weight: bold;
}

div.clientListingLinks {
  margin-top: 8px;
  background-color: #fff;
  margin-bottom: 5px;
  border: solid 1px #ccc;
  padding: 5px;
  line-height: 15px;
  text-align: left;
}

td.edit {
  background-color: #fff;
  border: solid 1px #ccc;
}

tr {
  padding: 0;
  margin: 0;
}

.altRow {
  background-color: #fff;
}

.altRowSelected,
.altRowselected {
  background-color: #FFCC00;
}

.Selected,
.selected {
  background-color: #FFCC00;
}

tr.header {
  font-weight: bold;
  background-color: #efefef;
}

tr.header td.smalltext {
  font-weight: bold;
  font-size: xx-small;
}

tr.footer {
  font-weight: bold;
  background-color: #efefef;
}

tr.note {
  border: solid 10px #000;
}

input {
  font-family: Verdana, Arial, Times New Roman;
  font-size: x-small;
  margin: 0;
  padding: 1px;
  border: solid 1px #ccc;
  font-family: Verdana, Arial;
  color: #666;
}

textarea {
  font-family: Verdana, Arial, Times New Roman;
  font-size: small;
  margin: 0;
  padding: 1px;
  border: solid 1px #ccc;
  font-family: Verdana, Arial;
  color: #666;
}

h2 {
  font-family: Arial, Times New Roman;
  font-size: 85%;
  padding: 0 0 10px;
  width: auto;
}

input.address {
  width: 300px;
}

input.city {
  width: 200px;
}

input.zip {
  width: 50px;
}

input.zipPlus4 {
  width: 40px;
}

input.phone {
  width: 200px;
}

input.fax {
  width: 200px;
}

input.name {
  width: 300px;
}

input.clientNumber,
input.clientnumber {
  width: 50px;
}

input.currencyConverter {
  width: 60px;
}

input.checkBox {
  border: none;
  width: 15px;
}

input.matterNumber {
  width: 50px;
}

input.file {
  width: 300px;
}

.money {
  color: Green;
  text-align: right;
}

input.money {
  padding: 1px;
}

select {
  border: solid 1px #ccc;
  font-family: Verdana, Arial;
  color: #666;
  font-size: xx-small;
}

select.addNew {
  border: solid 1px #ccc;
  font-family: Verdana, Arial;
  font-size: xx-small;
  width: 280px;
}

select.funds {
  width: 300px;
}

select.clients {
  width: 300px;
}

input.button {
  font-size: x-small;
  color: #fff;
  padding: 0;
  background-color: #63859F;
  width: 150px;
}

input.buttonDisabled {
  font-size: xx-small;
  color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  font-weight: normal;
  background-color: #63859F;
  width: 150px;
}

input.radio {
  border: none;
  width: 20px;
}

/* tableruler test */
tr.hover {
  background-color: yellow;
}

div#reportSteps {
  position: relative;
  left: 150px;
  top: 0;
}

span.hidLink {
  color: #cccccc;
  text-decoration: underline;
}

span.hidText {
  color: #999999;
}

table#aggregateSalesListing {
  background-color: Yellow;
  padding: 0;
  margin: 0;
  border-top: dashed 1px #999;
  border-bottom: dashed 1px #999;
  font-size: medium;
}

table#aggregateSalesListing tr.header {
  font-weight: bold;
  background-color: Yellow;
}

table#aggregateSalesListing tr.nonUSD {
  color: #ccc;
  background-color: Yellow;
}

tr.highlight {
  background-color: Yellow;
}

td.aggregateSalesFundHeader {
  border-bottom: solid 1px #999;
  border-top: solid 1px #999;
  font-weight: bold;
  background-color: #63859F;
  color: #fff;
}

td.aggregateSalesFundHeader a {
  font-size: xx-small;
  color: #fff;
}

td.aggregateAdditionalNotesMid {
  border-bottom: solid 1px #999;
  border-left: solid 1px #999;
}

td.aggregateAdditionalNotesEnd {
  border-bottom: solid 1px #999;
}

td.aggregateSalesExpandCell {
  background-color: #efefef;
}

td.aggregateSalesExpandCellAltRow {
  background-color: #fff;
}

td.topLevelHeader {
  border-bottom: dashed 1px #999;
}

table.listing td {
  font-family: Verdana, Arial, Times New Roman;
  font-size: 70%;
  vertical-align: top;
  padding: 5px;
  margin: 0;
  border-left: solid 1px #ccc;
}

td.a506DListingSubtotals {
  border-top: solid 1px #999;
}

td.a506DListingTotals {
  border-top: dashed 1px #999;
  border-left: none;
}

td.ExcelErrorCell {
  background-color: #cc3333;
  border-bottom: solid 1px #efefef;
  color: #fff;
}

table.gridLayout {
  width: auto;
  border-left: solid 1px #999;
  border-bottom: solid 1px #999;
}

table.gridLayout tr {
  padding: 0;
}

table.gridLayout td {
  border-left: none;
  width: 10px;
  border-bottom: none;
  border-top: solid 1px #999;
  border-right: solid 1px #999;
}

table.gridLayout td.footerGrid {
  border-left: none;
  border-bottom: none;
  border-top: solid 1px #999;
  border-right: none;
}

td.fundSalesListingTotals {
  border-top: dashed 1px #999;
  border-bottom: dashed 1px #999;
  border-left: none;
  vertical-align: top;
}

td.fundSalesListingTotalsMoney {
  border-top: dashed 1px #999;
  border-bottom: dashed 1px #999;
  vertical-align: top;
  border-left: none;
  color: Green;
  text-align: right;
}

div#section p {
  font-size: small;
}

div.module td {
  font-size: 70%;
}

div.moduleDisabled td,
div.moduledisabled td {
  font-size: 70%;
  color: #ccc;
  filter: alpha(opacity=90);
}

table.layout td.jumpLinks {
  font-size: 70%;
}

div.module table.inset {
  background-color: #fff;
  border: solid 1px #ccc;
}

.g-recaptcha {
  display: inline-block;
  padding-top: 30px;
}

div#headerMenu {
  box-sizing: border-box;
  height: 40px;
  background-color: #424242;
  color: #E2E2E2;
  white-space: nowrap;
}
div#headerMenu a {
  text-decoration: none;
  margin: 0 5px;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: normal;
}
div#headerMenu a:hover {
  color: #8BB4C4;
}

div#header {
  box-sizing: border-box;
  background-color: #8BB4C4;
  padding: 0 100px;
  height: 100px;
}

.header-logo-img {
  height: 30px;
  margin-top: 35px;
  width: auto;
}

div#headerInfoBlock {
  background-color: transparent;
  height: 100px;
  float: right;
  line-height: 100px;
  font-size: 16px;
  padding: 0 100px;
  padding-right: 0;
  padding-left: 0;
  border: none;
  color: #005E8A;
  white-space: nowrap;
}

div.moduleHeader.big-header,
div.moduleheader.big-header {
  margin: 30px 100px;
  background-color: #005E8A;
  color: #FFF;
  height: 154px;
  line-height: 154px;
  font-size: 40px;
  text-align: center;
  background-image: url("/img/complex-vector.png");
  background-size: 60% auto;
  background-repeat: no-repeat;
  background-position: center 20%;
}
@media (max-width: 1200px) {
  div.moduleHeader.big-header,
  div.moduleheader.big-header {
    font-size: 3vw;
  }
}

.full-width-wrapper {
  padding: 10px 100px;
}

.border-grey {
  border: 1px solid #E2E2E2;
}

.tile-grey {
  background-color: #f5f5f5;
  border: 1px solid #E2E2E2;
}

div#section {
  padding-bottom: 30px;
}

div.module.single-column {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 20%;
  margin-right: 20%;
}

.unstyled-module .module {
  border: 0 !important;
  padding: 0 !important;
  background-color: #fff !important;
}
.unstyled-module .module.lone-button .button:only-of-type {
  margin-top: 1em;
}

input.button.big-button {
  color: #FFF;
  background-color: #005E8A;
  text-transform: uppercase;
  border: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  font-size: 15px;
  height: 40px;
}
input.button.big-button:hover {
  background-color: #8BB4C4;
}
input.button.big-button:disabled {
  background-color: #8BB4C4;
  color: #FFF;
  cursor: not-allowed;
}

input.name {
  font-size: 15px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.input-row {
  margin-top: 1em;
}
.input-row input:first-child,
.input-row select:first-child {
  margin-left: 0;
}

.narrow-column {
  text-align: center;
}

.page-chunk:not(:last-of-type) {
  border-bottom: 2px solid #E2E2E2;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.full-width {
  box-sizing: border-box;
  width: 100% !important;
}

.sidebar-offset {
  margin-left: 350px;
}

.formatted-text {
  white-space: pre-wrap;
}

.module-flex-columns {
  columns: 4;
}
@media (max-width: 1024px) {
  .module-flex-columns {
    columns: 3;
  }
}
@media (max-width: 850px) {
  .module-flex-columns {
    columns: 2;
  }
}
@media (max-width: 650px) {
  .module-flex-columns {
    columns: 1;
  }
}
.module-flex-columns .module-flex-column-child {
  margin-bottom: 20px;
  break-inside: avoid;
  overflow: hidden;
}

.interior-page table:not(.unstyled-table) {
  border-collapse: collapse;
}
.interior-page table:not(.unstyled-table).listing td {
  border-left: 0;
}
.interior-page table:not(.unstyled-table) td input.button:not(.go-button):not(.button-override) {
  background-color: transparent;
  color: #005E8A;
}
.interior-page table:not(.unstyled-table) td input.button:not(.go-button):not(.button-override):hover {
  color: #8BB4C4;
}
.interior-page table:not(.unstyled-table) td input.button:not(.go-button):not(.button-override):disabled {
  color: #8BB4C4;
}
.interior-page table:not(.unstyled-table) tr.header {
  background-color: #fff;
}
.interior-page table:not(.unstyled-table) tr.header td,
.interior-page table:not(.unstyled-table) tr.header th {
  font-size: 15px;
  padding: 10px 25px 10px;
  text-align: left;
}
@media screen and (max-width: 1500px) {
  .interior-page table:not(.unstyled-table) tr.header td,
  .interior-page table:not(.unstyled-table) tr.header th {
    padding: 10px 10px;
  }
}
@media screen and (max-width: 1350px) {
  .interior-page table:not(.unstyled-table) tr.header td,
  .interior-page table:not(.unstyled-table) tr.header th {
    padding: 10px 7px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1279px) {
  .interior-page table:not(.unstyled-table) tr.header td,
  .interior-page table:not(.unstyled-table) tr.header th {
    font-size: 13px;
  }
}
.interior-page table:not(.unstyled-table):not(.table-minimal) tr.header:first-of-type {
  background-color: rgba(139, 180, 196, 0.5);
  height: 42px;
  border: thin solid rgba(199, 201, 200, 0.5);
}
.interior-page table:not(.unstyled-table):not(.table-minimal) tr.header:first-of-type td {
  padding: 10px 25px;
}
.interior-page table:not(.unstyled-table) tr:not(.header) {
  border: thin solid rgba(199, 201, 200, 0.5);
}
.interior-page table:not(.unstyled-table) tr:not(.header) td.aggregateSalesFundHeader {
  background-color: rgba(139, 180, 196, 0.5);
}
.interior-page table:not(.unstyled-table) tr:not(.header) td.aggregateSalesFundHeader a {
  font-weight: normal;
  text-decoration: none;
  color: #005E8A;
  font-size: 15px;
}
.interior-page table:not(.unstyled-table) tr:not(.header) td.aggregateSalesFundHeader a:hover {
  color: #8BB4C4;
}
.interior-page table:not(.unstyled-table) tr.note {
  border: 0;
  color: #c51f4b;
}
.interior-page table:not(.unstyled-table) tr.note strong {
  font-weight: normal;
}
.interior-page table:not(.unstyled-table) tr.hover,
.interior-page table:not(.unstyled-table) tr.altRow.hover {
  background-color: #8BB4C4;
}
.interior-page table:not(.unstyled-table) tr.hover a:hover,
.interior-page table:not(.unstyled-table) tr.altRow.hover a:hover {
  color: #FFF;
}

.interior-page table:not(.pika-table) tr:not(.header) td {
  vertical-align: bottom;
  color: black;
  font-size: 15px;
  padding: 10px 25px;
}
@media screen and (max-width: 1500px) {
  .interior-page table:not(.pika-table) tr:not(.header) td {
    padding: 10px 10px;
  }
}
@media screen and (max-width: 1350px) {
  .interior-page table:not(.pika-table) tr:not(.header) td {
    padding: 10px 7px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1279px) {
  .interior-page table:not(.pika-table) tr:not(.header) td {
    font-size: 13px;
  }
}
.interior-page table:not(.pika-table) tr:not(.header) td.jumpLinks {
  vertical-align: top;
}
.interior-page table:not(.pika-table) tr:not(.header) td input.checkbox,
.interior-page table:not(.pika-table) tr:not(.header) td input.radio {
  vertical-align: middle;
}

table.grey-unbordered {
  background-color: #f5f5f5;
  border: 1px solid #E2E2E2;
}
table.grey-unbordered tbody tr {
  outline: 0 !important;
}
table.grey-unbordered tbody tr.hover {
  background-color: #8BB4C4;
}
table.grey-unbordered tbody .module {
  border: 0;
  padding: 0;
}

.table-listing-striped-page table a,
table.table-listing-striped a {
  color: #005E8A;
  text-decoration: none;
}
.table-listing-striped-page table a:hover,
table.table-listing-striped a:hover {
  color: #8BB4C4;
}
.table-listing-striped-page table tr,
table.table-listing-striped tr {
  outline: 0 !important;
  background-color: #f5f5f5;
}
.table-listing-striped-page table tr.altRow,
table.table-listing-striped tr.altRow {
  background-color: #fff;
}
.table-listing-striped-page table td,
table.table-listing-striped td {
  border: 1px solid #E2E2E2 !important;
}
.table-listing-striped-page table td:not(:first-of-type),
table.table-listing-striped td:not(:first-of-type) {
  border-left: 0 !important;
}
.table-listing-striped-page table td:not(:last-of-type),
table.table-listing-striped td:not(:last-of-type) {
  border-right: 0 !important;
}

.table-minimal-page table,
table.table-minimal {
  border: 1px solid #E2E2E2;
}
.table-minimal-page table tr,
table.table-minimal tr {
  outline: 0 !important;
}

.table-invisible tr,
.table-invisible-page table tr {
  padding: 0 !important;
  outline: 0 !important;
}
.table-invisible tr td,
.table-invisible-page table tr td {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 0 !important;
}
.table-invisible.inset,
.table-invisible-page table.inset {
  background-color: transparent !important;
  border: 0 !important;
}

table.table-minimal-tile-grey td {
  border: 0;
  background-color: #f5f5f5;
}
table.table-minimal-tile-grey tr {
  outline: 0 !important;
}
table.table-minimal-tile-grey a {
  color: #005E8A;
  text-decoration: none;
}
table.table-minimal-tile-grey a:hover {
  color: #8BB4C4;
}

.table-minimal-tile-grey-page table {
  border: 1px solid #E2E2E2;
}
.table-minimal-tile-grey-page table td {
  border: 0;
  background-color: #f5f5f5;
}
.table-minimal-tile-grey-page table tr {
  outline: 0 !important;
}
.table-minimal-tile-grey-page table a {
  color: #005E8A;
  text-decoration: none;
}
.table-minimal-tile-grey-page table a:hover {
  color: #8BB4C4;
}

.table-full-width-page table {
  box-sizing: border-box;
  width: 100%;
}

.steps-row-wrapper {
  padding: 0 100px;
}

table#steps {
  background-color: #fff;
  border-bottom: 1px solid #E2E2E2;
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
}
table#steps a {
  color: #005E8A;
  text-decoration: none;
}
table#steps a:hover {
  color: #8BB4C4;
}
table#steps tr {
  outline: 0 !important;
  width: 100%;
}
table#steps td {
  font-size: 16px;
}
table#steps td.navSelected {
  font-size: 16px;
}
table#steps td:nth-of-type(odd) {
  padding-right: 0;
  width: 10%;
}

.table-form-page table tr,
table.table-form tr {
  outline: 0 !important;
}
.table-form-page table tr td,
table.table-form tr td {
  padding-left: 0 !important;
}

div.moduleFooter.single-column,
div.modulefooter.single-column {
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
}

div#footer {
  background-color: #424242;
  color: #E2E2E2;
  font-size: 14px;
  height: 90px;
  line-height: 90px;
  margin: 0;
  padding: 0 100px;
}

div#section {
  padding-left: 0;
  padding-right: 0;
}

body.home div.moduleFooter .button,
body.home div.modulefooter .button {
  margin-left: 5%;
  margin-right: 5%;
  width: 30%;
}

.viewsales-page {
  min-width: 1210px;
}

.popup-page-update .moduleheader {
  font-size: 24px;
}
.popup-page-update table:not(.unstyled-table) tr:not(.header) td {
  font-size: 15px;
  width: 75%;
}
.popup-page-update input.button {
  color: #FFF;
  background-color: #005E8A;
  text-transform: uppercase;
  border: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.popup-page-update input.button:hover {
  background-color: #8BB4C4;
}
.popup-page-update input.button:disabled {
  background-color: #8BB4C4;
  color: #FFF;
  cursor: not-allowed;
}
.popup-page-update input {
  height: 30px;
  font-size: 15px;
}
.popup-page-update select {
  font-size: 15px;
}

body.login .name {
  width: 35%;
}
body.login .password {
  margin-top: 15px;
}
body.login .login-error li {
  color: #cc3333 !important;
  text-align: left;
  width: 40%;
  margin: 0 auto;
}
body.login .login-btn {
  margin-top: 20px;
}
body.login .forgot-password {
  margin-top: 15px;
}
body.login .forgot-password a {
  color: #005E8A;
  text-decoration: none;
}
body.login #txtUserName {
  margin-bottom: 15px;
}
body.login .button {
  width: 30%;
}

body.viewsales-page div.module {
  background-color: #fff;
  border: 0;
  padding: 0;
}
body.viewsales-page .clientHeader {
  font-weight: bold;
  height: 42px;
  outline: thin solid rgba(199, 201, 200, 0.5);
}
body.viewsales-page .aggregateSalesFundHeader {
  border: 0;
  min-width: 10em;
}
body.viewsales-page .aggregateSalesExpandCell {
  background-color: #fff;
}
body.viewsales-page .expand-row {
  outline: none !important;
  height: auto;
}

body.admin-home .button {
  width: auto !important;
}

body.client-home-page #section {
  display: flex;
  flex-direction: column;
}
body.client-home-page #section .module {
  order: -1;
  margin-top: 20px;
  margin-bottom: 0;
}
body.client-home-page .client-fund-listing-p {
  margin-top: 20px;
  display: flex;
  order: -2;
}
body.client-home-page .moduleheader {
  margin-top: 20px;
  display: flex;
  order: -3;
}
body.client-home-page table a {
  color: #005E8A;
  text-decoration: none;
}
body.client-home-page table a:hover {
  color: #8BB4C4;
}
body.client-home-page table tr {
  background-color: #f5f5f5;
}
body.client-home-page table tr.altRow {
  background-color: #fff;
}
body.client-home-page table tr.group-header {
  font-weight: bold;
}
body.client-home-page table tr.group-header td {
  vertical-align: top !important;
}
body.client-home-page a {
  color: #005E8A;
  text-decoration: none;
  font-weight: bold;
}

body.ClientSummary-page .moduleFooter .button,
body.ClientSummary-page .modulefooter .button,
body.editFundInfo-page .moduleFooter .button,
body.editFundInfo-page .modulefooter .button,
body.fundSummary-page .moduleFooter .button,
body.fundSummary-page .modulefooter .button,
body.sEditFundInfo-page .moduleFooter .button,
body.sEditFundInfo-page .modulefooter .button {
  color: #FFF !important;
  background-color: #005E8A !important;
  text-transform: uppercase !important;
  border: 0 !important;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
body.ClientSummary-page .moduleFooter .button:hover,
body.ClientSummary-page .modulefooter .button:hover,
body.editFundInfo-page .moduleFooter .button:hover,
body.editFundInfo-page .modulefooter .button:hover,
body.fundSummary-page .moduleFooter .button:hover,
body.fundSummary-page .modulefooter .button:hover,
body.sEditFundInfo-page .moduleFooter .button:hover,
body.sEditFundInfo-page .modulefooter .button:hover {
  background-color: #8BB4C4 !important;
}
body.ClientSummary-page .moduleFooter .button:disabled,
body.ClientSummary-page .modulefooter .button:disabled,
body.editFundInfo-page .moduleFooter .button:disabled,
body.editFundInfo-page .modulefooter .button:disabled,
body.fundSummary-page .moduleFooter .button:disabled,
body.fundSummary-page .modulefooter .button:disabled,
body.sEditFundInfo-page .moduleFooter .button:disabled,
body.sEditFundInfo-page .modulefooter .button:disabled {
  background-color: #8BB4C4;
  color: #FFF;
  cursor: not-allowed;
}
body.ClientSummary-page .moduleFooterDisabled .button,
body.ClientSummary-page .moduleFooterdisabled .button,
body.ClientSummary-page .modulefooterdisabled .button,
body.editFundInfo-page .moduleFooterDisabled .button,
body.editFundInfo-page .moduleFooterdisabled .button,
body.editFundInfo-page .modulefooterdisabled .button,
body.fundSummary-page .moduleFooterDisabled .button,
body.fundSummary-page .moduleFooterdisabled .button,
body.fundSummary-page .modulefooterdisabled .button,
body.sEditFundInfo-page .moduleFooterDisabled .button,
body.sEditFundInfo-page .moduleFooterdisabled .button,
body.sEditFundInfo-page .modulefooterdisabled .button {
  background-color: #8BB4C4 !important;
  color: #FFF !important;
  cursor: not-allowed;
}

body.addNewFund-page table tr {
  outline: 0 !important;
}
body.addNewFund-page table tr td {
  padding-left: 0 !important;
}
body.addNewFund-page .modulefooter {
  margin-bottom: 30px;
}

body.addNewFundRelated-page .module table:not(.listing) tr {
  padding: 0 !important;
  outline: 0 !important;
}
body.addNewFundRelated-page .module table:not(.listing) tr td {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 0 !important;
}
body.addNewFundRelated-page .module table:not(.listing).inset {
  background-color: transparent !important;
  border: 0 !important;
}

body.interior-page.popup-page div#section {
  padding-left: 0;
  padding-right: 0;
}
body.interior-page.popup-page tr:not(.header) td {
  font-size: 15px;
}
body.interior-page a {
  color: #005E8A;
  text-decoration: none;
}
body.interior-page a:hover {
  color: #8BB4C4;
}
body.interior-page div#section {
  padding: 10px 100px;
}
body.interior-page #header + h1,
body.interior-page #headerInfoBlock + h1 {
  background-color: #fff;
  border: 0;
  color: #656365;
  font-size: 16px;
  padding: 20px 100px;
}
body.interior-page input,
body.interior-page select {
  box-sizing: border-box;
  font-size: 15px;
  height: 33px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 15px;
}
body.interior-page input:not(.clientnumber):not(.funds):not(.long-dropdown),
body.interior-page select:not(.clientnumber):not(.funds):not(.long-dropdown) {
  width: auto;
  max-width: 100%;
}
body.interior-page input:not(.clientnumber):not(.funds):not(.long-dropdown).go-button,
body.interior-page select:not(.clientnumber):not(.funds):not(.long-dropdown).go-button {
  width: 5em;
}
body.interior-page select {
  padding-right: 0;
}
body.interior-page .clientnumber {
  width: 6em !important;
}
body.interior-page .funds,
body.interior-page .long-dropdown {
  width: 30%;
}
body.interior-page .small-font-dropdown {
  font-size: 11px;
  width: auto;
  max-width: 100%;
}
body.interior-page br + input,
body.interior-page br + select,
body.interior-page input:first-child,
body.interior-page p + input,
body.interior-page p + select,
body.interior-page select:first-child {
  margin-left: 0;
}
body.interior-page input.button {
  color: #FFF;
  background-color: #005E8A;
  text-transform: uppercase;
  border: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
body.interior-page input.button:hover {
  background-color: #8BB4C4;
}
body.interior-page input.button:disabled {
  background-color: #8BB4C4;
  color: #FFF;
  cursor: not-allowed;
}
body.interior-page .file {
  padding-left: 0;
  border: 0;
}
body.interior-page .moduleHeader,
body.interior-page .moduleheader {
  font-size: 24px;
  margin-bottom: 5px;
  padding: 0 0 5px;
}
body.interior-page .moduleHeader span.name,
body.interior-page .moduleheader span.name {
  font-size: small;
}
body.interior-page div.moduleHeaderDisabled,
body.interior-page div.moduleHeaderdisabled,
body.interior-page div.moduleheaderDisabled,
body.interior-page div.moduleheaderdisabled {
  font-size: 24px;
  margin-bottom: 5px;
  padding: 0 0 5px;
  color: #ccc;
}
body.interior-page div.moduleHeaderDisabled span.name,
body.interior-page div.moduleHeaderdisabled span.name,
body.interior-page div.moduleheaderDisabled span.name,
body.interior-page div.moduleheaderdisabled span.name {
  font-size: small;
}
body.interior-page div.moduleHeaderDisabled a,
body.interior-page div.moduleHeaderdisabled a,
body.interior-page div.moduleheaderDisabled a,
body.interior-page div.moduleheaderdisabled a {
  color: #ccc !important;
}
body.interior-page .moduleFooter,
body.interior-page .modulefooter {
  font-size: 16px;
}
body.interior-page div.moduleFooterDisabled,
body.interior-page div.moduleFooterdisabled,
body.interior-page div.modulefooterDisabled,
body.interior-page div.modulefooterdisabled {
  font-size: 16px;
  color: #efefef;
}
body.interior-page div.moduleFooterDisabled a,
body.interior-page div.moduleFooterdisabled a,
body.interior-page div.modulefooterDisabled a,
body.interior-page div.modulefooterdisabled a {
  color: #ccc !important;
}
body.interior-page .module,
body.interior-page .moduleClientManagement {
  background-color: #f5f5f5;
  border: 1px solid #E2E2E2;
  margin-bottom: 20px;
  padding: 10px 24px;
}
body.interior-page div.moduleDisabled,
body.interior-page div.moduledisabled {
  background-color: #f5f5f5;
  border: 1px solid #E2E2E2;
  margin-bottom: 20px;
  padding: 10px 24px;
  color: #ccc;
}
body.interior-page div.moduleDisabled a,
body.interior-page div.moduledisabled a {
  color: #ccc !important;
}
body.interior-page .moduleClientManagement {
  border: 1px solid #E2E2E2 !important;
  margin-bottom: 20px;
}
body.interior-page div#error {
  background-color: #f5f5f5;
  border: 1px solid #E2E2E2;
  color: #c51f4b;
}
body.interior-page table.aggregateSalesListing tr.header,
body.interior-page table.aggregateSalesListing tr.nonUSD,
body.interior-page tr.highlight {
  background-color: rgb(245, 219, 108);
}
body.interior-page a.anchorJumpLink {
  display: block;
}

body.summary-page #headerInfoBlock + h1 {
  padding-left: 100px;
}
body.summary-page .steps-row-wrapper {
  box-sizing: border-box;
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
}
body.summary-page table#steps {
  border-bottom: 0;
}
body.summary-page table#steps td:nth-of-type(odd) {
  padding-left: 0;
  width: 1em;
}
body.summary-page div#section {
  border: 0;
  padding: 0 100px 0 0;
}
body.summary-page div#section.hide-jumplinks-section {
  border: 0;
  padding: 0 100px 0 100px;
}
body.summary-page div.module {
  background-color: #fff;
  border: 0;
  padding: 0;
}
body.summary-page .moduleBackToTop {
  padding-left: 0;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E2E2E2;
}
body.summary-page .moduleBackToTop a {
  color: #005E8A;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  text-transform: none;
}
body.summary-page .moduleBackToTop a:hover {
  color: #8BB4C4;
}
body.summary-page .jumpLinks {
  padding-left: 100px !important;
}
body.summary-page table.layout {
  width: 100%;
}
body.summary-page table.layout tr {
  outline: 0 !important;
}
body.summary-page table.layout td .modulefooter input.button,
body.summary-page table.layout td.moduleFooter input.button {
  color: #FFF;
  background-color: #005E8A;
  text-transform: uppercase;
  border: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  color: #FFF;
  margin-left: 0;
  width: 29%;
}
body.summary-page table.layout td .modulefooter input.button:hover,
body.summary-page table.layout td.moduleFooter input.button:hover {
  background-color: #8BB4C4;
}
body.summary-page table.layout td .modulefooter input.button:disabled,
body.summary-page table.layout td.moduleFooter input.button:disabled {
  background-color: #8BB4C4;
  color: #FFF;
  cursor: not-allowed;
}
body.summary-page p.tile-grey {
  margin-bottom: 0;
  padding: 25px;
}
body.summary-page div.module table.inset,
body.summary-page table table {
  background-color: #f5f5f5;
  border: 1px solid #E2E2E2;
  width: 100%;
}
body.summary-page div.module table.inset tbody tr,
body.summary-page table table tbody tr {
  outline: 0 !important;
}
body.summary-page div.module table.inset tbody tr.hover,
body.summary-page table table tbody tr.hover {
  background-color: #8BB4C4;
}
body.summary-page div.module table.inset tbody .module,
body.summary-page table table tbody .module {
  border: 0;
  padding: 0;
}
body.summary-page div.module table.inset tr.header td,
body.summary-page table table tr.header td {
  background-color: #f5f5f5;
  border: 1px solid #E2E2E2;
}
body.summary-page div.module table.inset a,
body.summary-page table table a {
  color: #005E8A;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
}
body.summary-page div.module table.inset a:hover,
body.summary-page table table a:hover {
  color: #8BB4C4;
}
body.summary-page div.module table.inset a.no-caps,
body.summary-page table table a.no-caps {
  text-transform: none;
}
body.summary-page table.listing a {
  color: #005E8A;
  text-decoration: none;
}
body.summary-page table.listing a:hover {
  color: #8BB4C4;
}
body.summary-page table.listing tr {
  outline: 0 !important;
  background-color: #f5f5f5;
}
body.summary-page table.listing tr.altRow {
  background-color: #fff;
}
body.summary-page table.listing td {
  border: 1px solid #E2E2E2 !important;
}
body.summary-page table.listing td:not(:first-of-type) {
  border-left: 0 !important;
}
body.summary-page table.listing td:not(:last-of-type) {
  border-right: 0 !important;
}

.jumpLinks a {
  color: #005E8A;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 1em;
}
.jumpLinks a:hover {
  color: #8BB4C4;
}
.jumpLinks.hide-jumplinks {
  display: none;
}

.jumpLinks-wrapper {
  padding-right: 12.5px;
  border-right: 1px solid #E2E2E2;
  margin-right: -12.5px;
}

.sales .container {
  width: 1371px;
  overflow: scroll;
  margin-left: 100px;
  height: 480px;
}
.sales .frozen-header {
  margin-left: 100px;
}
.sales .frozen-header table {
  border-collapse: collapse;
}
.sales .frozen-header table td {
  padding: 10px 0 !important;
}
.sales .frozen-header table div {
  padding-left: 25px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}

.overlay-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 500px;
  padding: 15px;
  font-family: Verdana, Arial, Times New Roman;
  font-size: 20px;
  text-align: center;
}
.overlay-message p {
  font-size: 20px;
}

.overlay-message-button {
  margin-top: 20px;
  margin-bottom: 10px;
}

body {
  background-color: #fff;
}