body.summary-page {
    #headerInfoBlock + h1 {
        padding-left: $sidebar-side-margin;
    }

    .steps-row-wrapper {
        box-sizing: border-box;
        //@include sidebar-offset(padding-left);
        padding-left:100px;
        padding-right: $site-side-margin;
        width: 100%;
    }

    table#steps {
        border-bottom: 0;

        td:nth-of-type(odd) {
            padding-left: 0;
            width: 1em;
        }

        td:nth-of-type(even) {
            //let the width be auto
        }
    }

    div#section {
        border: 0;
        padding: 0 $site-side-margin 0 0;

        &.hide-jumplinks-section{
            border: 0;
            padding: 0 $site-side-margin 0 $site-side-margin;
        }
    }

    div.module {
        background-color: $site-background;
        border: 0;
        padding: 0;
    }

    .moduleBackToTop {
        padding-left: 0;
        margin-bottom: calc($block-vertical-gap / 2);
        padding-bottom: calc($block-vertical-gap / 2);
        border-bottom: 1px solid $subtle-grey;

        a {
            @include jumplink-styling();
            text-transform: none;
        }
    }

    .jumpLinks {
        padding-left: $site-side-margin !important;
    }

    table.layout {
        width: 100%;

        tr {
            outline: 0 !important;
        }

        td .modulefooter,
        td.moduleFooter {
            input.button {
                @include button-styling();
                color: $text-white;
                margin-left: 0;
                width: 29%;
            }
        }
    }

    p.tile-grey {
        margin-bottom: 0;
        padding: $table-side-padding;
    }
    //all tables inside 'layout' table
    div.module table.inset,
    table table {
        @include table-flat-grey();

        tr.header td {
            @include tile-grey();
        }
        width: 100%;

        a {
            @include jumplink-styling();
            &.no-caps
            {
                text-transform: none;
            }
        }

    }

    table.listing {
        @include table-listing-striped();
    }
}
