$table-side-padding: 25px;
$table-vertical-padding: 10px;
$table-header-height: 42px;
$table-font-size: 15px;

.interior-page table:not(.unstyled-table) {
    border-collapse: collapse;
    //reset recurring old styles
    &.listing td {
        border-left: 0;
    }
    //just little plus icons
    td input.button:not(.go-button):not(.button-override) {
        background-color: transparent;
        color: $matte-blue;

        &:hover {
            color: $matte-light-blue;
        }

        &:disabled {
            color: $button-disabled-color;
        }
    }

    tr.header {
        background-color: $site-background;
        // margin-top: 10px;
        td,
        th {
            // border-collapse: collapse;
            font-size: $table-font-size;
            padding: $table-vertical-padding $table-side-padding $table-vertical-padding;
            text-align: left;
             @media screen and (max-width: 1500px) {
                padding: 10px 10px;
            }
            @media screen and (max-width: 1350px) {
                padding: 10px 7px;
                font-size:14px;
            }
             @media screen and (max-width: 1279px) {
                font-size:13px;
            }
        }
    }
    // first title is a differently styled header
    &:not(.table-minimal) tr.header:first-of-type {
        background-color: rgba($matte-light-blue, .5);
        height: $table-header-height;
        border: thin solid $table-border-color;

        td {
            padding: $table-vertical-padding $table-side-padding;
            // padding-bottom: $table-vertical-padding
        }
    }

    tr:not(.header) {
        border: thin solid $table-border-color;

        td.aggregateSalesFundHeader {
            background-color: rgba($matte-light-blue, .5);

            a {
                font-weight: normal;
                text-decoration: none;
                color: $matte-blue;
                font-size: $table-font-size;

                &:hover {
                    color: $matte-light-blue;
                }
            }
        }
    }

    tr.note {
        border: 0;
        color: $error-red;

        strong {
            font-weight: normal;
        }
    }

    tr.hover,
    tr.altRow.hover {
        background-color: $matte-light-blue;

        a:hover {
            color: $text-white;
        }
    }
}

.interior-page table:not(.pika-table) {
    tr:not(.header) {
        td {
            vertical-align: bottom;
            color: black;
            font-size: $table-font-size;
            padding: $table-vertical-padding $table-side-padding;

            @media screen and (max-width: 1500px) {
                padding: 10px 10px;
            }
            @media screen and (max-width: 1350px) {
                padding: 10px 7px;
                font-size:14px;
            }
             @media screen and (max-width: 1279px) {
                font-size:13px;
            }
            &.jumpLinks {
                vertical-align: top;
            }

            input.checkbox,
            input.radio {
                vertical-align: middle;
            }
        }
    }
}

@mixin table-flat-grey {
     @include tile-grey();

    tbody {
        tr {
            outline: 0 !important;

            &.hover {
                background-color: $matte-light-blue;
            }
        }

        .module {
            border: 0;
            padding: 0;
        }
    }
}

table.grey-unbordered {
    @include table-flat-grey();
}
@mixin table-listing-striped {
    a {
        @include link-styling();
    }

    tr {
        outline: 0 !important;
        background-color: $tile-grey;

        &.altRow {
            background-color: $site-background;
        }
    }

    td {
        border: 1px solid $subtle-grey !important;

        &:not(:first-of-type) {
            border-left: 0 !important;
        }

        &:not(:last-of-type) {
            border-right: 0 !important;
        }
    }
}

.table-listing-striped-page table,
table.table-listing-striped {
    @include table-listing-striped();
}
@mixin table-minimal() {
     @include border-grey();

    tr {
        outline: 0 !important;
    }
}

.table-minimal-page table,
table.table-minimal {
    @include table-minimal();
}
@mixin table-invisible() {
    tr {
        padding: 0 !important;
        outline: 0 !important;

        td {
            padding-left: 0 !important;
            padding-right: 0 !important;
            border: 0 !important;
        }
    }

    &.inset {
        background-color: transparent !important;
        border: 0 !important;
    }
}

.table-invisible,
.table-invisible-page table {
    @include table-invisible()
}
 @mixin table-minimal-tile-grey{
    td {
        border: 0;
        background-color: $tile-grey;
    }

    tr {
        outline: 0 !important;
    }

    a {
        @include link-styling();
    }
}

table.table-minimal-tile-grey {
    @include table-minimal-tile-grey();
}

.table-minimal-tile-grey-page table {
    @include table-minimal-tile-grey();
    @include border-grey();
}

.table-full-width-page table {
    box-sizing: border-box;
    width: 100%;
}

.steps-row-wrapper {
    padding: 0 $site-side-margin;
}

table#steps {
    background-color: $site-background;
    border-bottom: 1px solid $subtle-grey;
    width: 100%;
    margin-bottom: $block-vertical-gap-small;
    table-layout: fixed;

    a {
        @include link-styling();
    }

    tr {
        outline: 0 !important;
        width: 100%;
    }

    td {
        font-size: 16px;

        &.navSelected {
            font-size: 16px;
        }

        &:nth-of-type(odd) {
            padding-right: 0;
            // text-align: right;
            width: 10%;
        }
    }
}
@mixin table-form {
    tr {
        outline: 0 !important;

        td {
            padding-left: 0 !important;
        }
    }
}

.table-form-page table,
table.table-form {
    @include table-form();
}
