div#section {
    padding-left: 0;
    padding-right: 0;
}

body.home {
    div.moduleFooter,
    div.modulefooter {
        .button {
            margin-left: 5%;
            margin-right: 5%;
            width: 30%;
        }
    }
}
.viewsales-page {
    min-width:1210px;
}
.popup-page-update{
    .moduleheader{
        font-size: 24px; 
    }
    table:not(.unstyled-table) tr:not(.header) td{
        font-size:15px;
        width: 75%;
    }
    input.button {
        @include button-styling();
        padding-top:4px;
        padding-bottom:4px;
        margin-top: 5px; 
        margin-bottom: 5px; 
    }
    input{
        height: 30px; 
        font-size: 15px;
    }
    select{
        font-size: 15px; 
    }
}
body.login {
    .name {
        width: 35%;
    }

    .password {
        margin-top: 15px;
    }

    .login-error li {
        color: #cc3333 !important;
        text-align: left;
        width: 40%;
        margin: 0 auto;
    }

    .login-btn {
        margin-top: 20px;
    }

    .forgot-password {
        margin-top: 15px;

        a {
            color: $matte-blue;
            text-decoration: none;
        }
    }

    #txtUserName {
        margin-bottom: calc($block-vertical-gap / 2);
    }

    .button {
        width: 30%;
    }
}

body.viewsales-page {
    div.module {
        @include no-background();
        padding: 0;
    }

    .clientHeader {
        font-weight: bold;
        height: $table-header-height;
        outline: thin solid $table-border-color;
    }

    .aggregateSalesFundHeader {
        // width: 13%;
        border: 0;
        min-width: 10em;
    }
    //TODO: why do these exist and are hidden?
    .aggregateSalesExpandCell {
        background-color: $site-background;
    }

    .expand-row {
        outline: none !important;
        height: auto;
    }
}

body.admin-home {
    //TODO: check if this important is still needed by the end
    .button {
        width: auto !important;
    }
}

body.client-home-page {
    #section {
        display: flex;
        flex-direction: column;

        .module {
            // display: block;
            order: -1;
            margin-top: $block-vertical-gap-small;
            margin-bottom: 0;
        }
    }

    .client-fund-listing-p {
        margin-top: $block-vertical-gap-small;
        display: flex;
        order: -2;
    }

    .moduleheader {
        margin-top: $block-vertical-gap-small;
        display: flex;
        order: -3;
    }

    table {
        a {
            @include link-styling();
        }

        tr {
            background-color: $tile-grey;

            &.altRow {
                background-color: $site-background;
            }

            &.group-header {
                font-weight: bold;

                td {
                  vertical-align: top !important;
                }
            }
        }
    }

    a {
        color: $matte-blue;
        text-decoration: none;
        font-weight: bold;
    }
}

body.ClientSummary-page,
body.editFundInfo-page,
body.fundSummary-page,
body.sEditFundInfo-page {
    .moduleFooter,
    .modulefooter {
        .button {
            @include button-styling(!important);
        }
    }
    //TODO: .moduledisabled, .moduleDisabled, ???
    .moduleFooterDisabled,
    .moduleFooterdisabled,
    .modulefooterdisabled {
        .button {
            @include button-disabled-styling(!important);
        }
    }
}

body.addNewFund-page {
    table {
        @include table-form()
    }

    .modulefooter {
        margin-bottom: $block-vertical-gap;
    }
}

body.addNewFundRelated-page {
    .module table:not(.listing) {
        @include table-invisible()
    }
}
