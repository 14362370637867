.jumpLinks {
    a {
        @include jumplink-styling();
        display: inline-block;
        margin-bottom: 1em;
    }

    &.hide-jumplinks
        {
        display: none;
    }
}

.jumpLinks-wrapper {
    padding-right: calc($table-side-padding / 2);
    border-right: 1px solid $subtle-grey;
    margin-right: calc($table-side-padding / -2);
}
