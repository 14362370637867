.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
}

.overlay-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 500px;
    padding: 15px;
    font-family: Verdana, Arial, Times New Roman;
    font-size: 20px;
    text-align: center;

    p {
      font-size: 20px;
    }
}

.overlay-message-button {
    margin-top: 20px;
    margin-bottom: 10px
}
